<template>
  <div class="countries-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">Country Content</h4>
          <div class="main-contents">
            <fd-input
              class="mt-2 px-0"
              type="text"
              :label="`Country Name`"
              v-model="country.name"
              :required="true"
            />
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Update</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { CountryModel } from "@/models";

export default {
  name: "countries-edit",
  data() {
    return {
      country: {
        name: ""
      }
    };
  },
  mounted() {
    this.loadCountry();
  },
  methods: {
    async loadCountry() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageCountries/getCountry",
          id
        );

        this.country = CountryModel.getCountryResponse(
          this._.cloneDeep(request)
        );

        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageCountries/updateCountries",
          {
            id: id,
            data: CountryModel.postCountryPayload(this.country)
          }
        );

        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Country updated successfully."
        });
        this.$router.push({ path: "/manage-countries" });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.countries-edit {
  form {
    @extend %formDesign;
  }
}
</style>